import React from 'react';

import logo from '../assets/images/envision_wellness_logo.png';
import { navigate } from 'gatsby';
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}
export default function Footer() {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };
  const today = new Date();
  const year = today.getFullYear();
  return (
    <section id="footer">
      <div className="inner">
        <div className="form-section" id="contact">
          <h3 className="major">Contact</h3>
          <p className="major-text">
            If you have questions or would like to schedule an initial
            consultation, please fill out this form. I can't wait to help you
            achieve your personal fitness goals!
          </p>
          <form
            name="contact"
            method="post"
            action="/thanks/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <div className="fields">
              <div className="field">
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </p>
              </div>
              <div className="field">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="field">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="field">
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={handleChange}
                />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
            </div>
            <button type="submit">Send Message</button>
          </form>
        </div>
        <ul className="copyright">
          <li>
            <img
              src={logo}
              alt="Envision Wellness logo"
              className="footer-logo"
            />
          </li>
          <li>&copy; {year} Envision Wellness. All rights reserved.</li>
          <li>
            Site by{' '}
            <a
              href="https://mercuryjane.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mercury Jane Media
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}
