import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
export default function Nav({ onMenuToggle = () => {} }) {
  return (
    <nav id="menu">
      <div className="inner">
        <h2>MENU</h2>
        <ul className="links">
          <li>
            <AnchorLink
              onClick={e => {
                onMenuToggle();
              }}
              to="/#banner"
              title="Home"
              className="stripped"
              stripHash
            />
          </li>

          <li>
            <AnchorLink
              onClick={e => {
                onMenuToggle();
              }}
              to="/#about"
              title="Meet Nikki"
              className="stripped"
              stripHash
            />

          </li>
          <li>
            <AnchorLink
              onClick={e => {
                onMenuToggle();
              }}
              to="/#services"
              title="Services"
              className="stripped"
              stripHash
            />
         
          </li>
          <li>
            <AnchorLink
              onClick={e => {
                onMenuToggle();
              }}
              to="/#testimonials"
              title="Testimonials"
              className="stripped"
              stripHash
            />
          </li>
          <li>
            <AnchorLink
              onClick={e => {
                onMenuToggle();
              }}
              to="/#contact"
              title="Contact"
              className="stripped"
              stripHash
            />
          </li>
        </ul>
        <a
          className="close"
          onClick={e => {
            e.preventDefault();
            onMenuToggle();
          }}
          href="#menu"
          aria-label="Close the menu"
        >
          {''}
        </a>
      </div>
    </nav>
  );
}
