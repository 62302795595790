module.exports = {
  siteTitle: 'Nikki Van Culin | Personal Trainer', // <title>
  manifestName: 'Nikki Van Culin | Personal Trainer',
  manifestShortName: 'Nikki Van Culin', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#472777',
  manifestThemeColor: '#472777',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/favicon.png',
  // pathPrefix: `/gatsby-starter-solidstate/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Envision Wellness',
  subHeading: 'Nikki Van Culin | Personal Trainer',
};
